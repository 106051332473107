import * as hightCharts from 'highcharts';
import { highChartsBase } from './highcharts-base';

export const highChartsTimeLineSeries = function(data) {
    console.log('highChartsTimeLineSeriesData', data);
    let threshold_guide_items = [];
    let event_period = null;
    if ('threshold_guide_items' in data.settings) {
        threshold_guide_items = data.settings['threshold_guide_items'];
    }
    if ('event_period' in data.settings) {
        event_period = data.settings['event_period'];
    }
    let start_yaxis_on_min = data.start_yaxis_on_min;
    let last_calculated_yaxis_min = null;
    let first_reset_triggered = false;
    let element = document.getElementById('chart_'+data.id);
    return Object.assign(
        {
            la_threshold_guide_items: threshold_guide_items,
            la_event_period: event_period,
            title: { text: data.name, style: { fontWeight: 'bold' } },
            boost: {
              enabled: true,
              useGPUTranslations: true,
            },
            chart: {
                spacingTop: 20,
                zoomType: 'x',
                style: {
                    fontFamily:
                        'Metropolis,"Avenir Next","Helvetica Neue",Arial,sans-serif'
                },
                events: {
                    afterPrint: function() {
                        window.dispatchEvent(new Event('resize'));
                    },
                    redraw: function() {
                        if (
                            this.options.la_threshold_guide_items.length > 0 &&
                            this.yAxis
                        ) {
                            this.yAxis[0].removePlotBand('thresholdGuide');
                            for (const thresholdGuide of this.options
                                .la_threshold_guide_items) {
                                this.yAxis[0].addPlotBand({
                                    id: 'thresholdGuide',
                                    from: thresholdGuide.low_threshold,
                                    to: thresholdGuide.high_threshold,
                                    color: hightCharts
                                        .color(thresholdGuide.color)
                                        .setOpacity(0.2)
                                        .get('rgba')
                                });
                            }
                        }
                        if (
                            this.options.la_event_period &&
                            this.xAxis
                        ) {
                            this.xAxis[0].removePlotLine('startEventLine');
                            this.xAxis[0].addPlotLine({
                                id: 'startEventLine',
                                color: 'red',
                                dashStyle: 'dash',
                                value: + new Date(this.options.la_event_period.event_start),
                                width: '2',
                                zIndex: 3,
                                label: {
                                    text: 'Event Start ' + new Date(this.options.la_event_period.event_start).toLocaleTimeString('en-US'),
                                    verticalAlign: 'middle',
                                    textAlign: 'center',
                                    style: {
                                        color: 'red',
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }
                                }
                            });
                            if(this.options.la_event_period.event_stop) {
                                this.xAxis[0].removePlotLine('stopEventLine');
                                this.xAxis[0].addPlotLine({
                                    id: 'stopEventLine',
                                    color: 'green',
                                    dashStyle: 'dash',
                                    value: + new Date(this.options.la_event_period.event_stop),
                                    width: '2',
                                    zIndex: 3,
                                    label: {
                                        text: 'Event Stop ' + new Date(this.options.la_event_period.event_start).toLocaleTimeString('en-US'),
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        style: {
                                            color: 'green',
                                            fontWeight: 'bold',
                                            fontSize: '14px'
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            },
            tooltip: {
                shared: true,
                split: false,
                enabled: true,
                // useHTML: true
                //outside: true,
                // followPointer: true,
                // followTouchMove: true,
                /*positioner: function(labelWidth, labelHeight, point) {
                    var tooltipX = point.plotX;
                    var tooltipY = point.plotY;
                    if (point.plotX + labelWidth > this.chart.chartWidth) {
                        console.log('xxxxxxxxxxxxx');
                        tooltipX -= labelWidth;
                    }
                    if(tooltipY < 0) {
                        tooltipY = 5;
                    }
                    console.log(tooltipX);
                    console.log(tooltipY);
                    console.log(this.chart.chartHeight)
                    return {
                        x: tooltipX,
                        y: tooltipY
                    };
                }*/
            },
            subtitle: {
                text:
                    document.ontouchstart === undefined
                        ? 'Click and drag in the plot area to zoom in'
                        : 'Pinch the chart to zoom in'
            },
            xAxis: {
                type: 'datetime',
                gridLineWidth: 1,
                opposite: false,
                ordinal: true,
                events: {
                    afterSetExtremes(e) {
                        let btns = document.querySelectorAll('.reset-zoom-btn');
                        for (let btn of btns) {
                            if (btn && +btn.id === data.id) btn.remove();
                        }

                        const showBtn =
                            this.chart.navigator.range !== undefined &&
                            this.chart.navigator.size -
                                this.chart.navigator.range >
                                0.1;

                        if(!last_calculated_yaxis_min) {
                            last_calculated_yaxis_min = e.target.chart.yAxis[0].min;
                        }
                        if (showBtn) {

                            let btn = document.getElementById('zoom_'+data.id);

                            if (typeof btn === 'undefined' || btn === null) {
                                  const btn = document.createElement('button');

                                  btn.innerHTML = 'Reset Zoom';
                                  btn.className =
                                  'btn btn-default reset-zoom-btn btn-outline-secondary btn-sm';
                                  btn.id = 'zoom_'+data.id;
                                  btn.style.zIndex='100000';
                                  btn.onclick = () => {
                                    this.chart.zoomOut();
                                        if (btn) {
                                            let block = document.getElementById('zoom_block_'+data.id);
                                            block.remove();
                                            btn.remove();
                                        }
                                    };


                            let children = element.firstChild.innerHTML;

                            const flexblock = document.createElement('div');
                            flexblock.className = 'zoom-btn-block';
                            flexblock.id = 'zoom_block_'+data.id;

                            flexblock.style = 'position: relative; width: 100%; display: flex; flex-flow: row; justify-content: right;margin-bottom:-3.5em;';
                            const flexitem = document.createElement('div');
                            flexitem.style = 'flex-grow: 0; width: 6em;margin-right:6em;margin-top:0.5em;z-index:10000;';
                            flexitem.className = 'zoom-btn-item';

                            flexitem.appendChild(btn);
                            flexblock.appendChild(flexitem);
                            element.firstChild.insertAdjacentElement('afterbegin', flexblock);


                            }


                            const dataMin = e.target.chart.yAxis[0].dataMin;
                            this.chart.update({
                                yAxis: {
                                    min: dataMin - (dataMin * .02),
                                    startOnTick: false
                                }
                            });
                        } else {
                            if(first_reset_triggered) {
                                this.chart.update({
                                    yAxis: {
                                        min: last_calculated_yaxis_min,
                                        startOnTick: !start_yaxis_on_min
                                    }
                                });
                            }
                            if(!first_reset_triggered) {
                                first_reset_triggered = true;
                            }

                        }
                    }
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
                opposite:false
            },
            legend: {
                enabled: true,
                symbolRadius: 1
            },
            plotOptions: {
                series: {
                    marker: {
                        symbol: 'circle',
                        enabledThreshold: 5
                    },
                    dataGrouping: {
                        enabled: data.data_grouping,
                    }
                }
            },
            scrollbar: {
                height: 24,
                buttonArrowColor: 'gray',
                buttonBorderRadius: 1,
                rifleColor: 'gray',
                trackBackgroundColor: 'white',
                trackBorderWidth: 1,
                trackBorderColor: 'silver',
                trackBorderRadius: 1
            },
            rangeSelector: {
                enabled: false
            },
            navigator: {
                enabled: true
            },

            series: []
        },
        highChartsBase
    );
};


