<div *ngIf="showForm === 'login'" class="main-container fl-login login-container">
  <div class="trademark">
    <p>Don't just use better energy, <br> <span>use energy better.</span> ™</p>
  </div>
  <div class="container container-table">
    <div class="login-table-middle">
 
      <div class="clr-col-md-8 clr-offset-md-2">
        <div class="login-card">
          <div class="card-group">

            <div class="card-left py-3 hidden-md-down card-login card-left">
              <div class="card-block text-xs-center">
                <div class="logo-wrapper">
                  <img src="assets/img/logo_200px.png" class="logo-login-initial" alt="Legend Analytics">
                </div>
              </div>
            </div>
            <div class="card-right p-2">



              <div class="card-block">
                <div class="card-text">

                  <h1>{{ corp_name }}</h1>
                  <p class="text-muted">Please login to the system</p>
                  <form [formGroup]="loginForm">
                    <div class="clr-row username-block">

                      <clr-input-container class="login-field">
                        <input clrInput id="username" type="text" formControlName="username" name="username" placeholder="Username" />
                        <clr-control-error *clrIfError="'required'">Username Is Required</clr-control-error>
                      </clr-input-container>
                    </div>
                    <div class="clr-row password-block">
                      <clr-password-container class="password-field">
                        <input clrPassword id="password" formControlName="password" type="password" placeholder="Password" autocomplete="current-password" />
                        <clr-control-error *clrIfError="'required'">Password is required
                        </clr-control-error>
                      </clr-password-container>
                      <div class="row">
                        &nbsp;<br />
                      </div>
                    </div>
                    <div class="clr-row">
                      <div [@messageAnimation]="messageState" *ngIf="failedLogin" class="adjust-alert alert alert-danger alert-item alert-vertical" style="margin-top:-1.7em;">
                        <div class="clr-row">
                          <div class="clr-col-sm-1 error-icon">
                            <clr-icon shape="error-standard"></clr-icon>
                          </div>
                          <div class="clr-col-sm-10 error-text">
                            <span class="alert-text">
                                        Invalid username or password
                                        </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clr-row row">

                      <div class="clr-col-1">
                           <button
                           (click)="submit()"
                            [disabled]="loggingIn"
                            class="btn btn-primary"
                            >
                            Login
                           </button> 
                      </div>
                      <div class="clr-col-1 adjust-left">
                           <span *ngIf="loggingIn" class="spinner spinner-inline"></span>
                      </div>
                    </div>
                    <div class="clr-row row">
                      <div class="clr-col-10 message-adj-vertical">
                          <span *ngIf="loggingIn">{{ loginMessage}}</span>
                      </div>
                    </div>

                    <div class="clr-row row recover-button">
                      <button type="button" routerLink="/recover" class="btn btn-link">Can't log in?</button>
                    </div>
                  </form>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showForm === 'welcome'" class="main-container fl-login login-container">
  <div class="container container-table">
    <div class="login-table-middle">
      <div class="clr-col-md-8 clr-offset-md-2">
        <div class="card login-card">
          <div class="card-group">

            <div class="card-left py-3 hidden-md-down card-login card-left">
              <div class="card-block text-xs-center">
                <div class="logo-wrapper">
                  <img src="assets/img/logo_200px.png" class="logo-login" alt="Legend Analytics">
                </div>
              </div>
            </div>
            <div class="card-right p-2">

              <form class="login" name="welcomeForm" (ngSubmit)="ref.form.valid && setPassword()" #ref="ngForm" novalidate>

              <div class="card-block">



                <h1>Legend Analytics</h1>
                <div>
                  <p class="text-muted">Create a new password:</p>
                  <div class="login-group" [ngClass]="{ 'has-error': ref.submitted && !password.valid }">
                    <div *ngIf="ref.submitted && password.hasError('required')" class="help-block">Password is
                      required
                    </div>
                    <div *ngIf="ref.submitted && password.hasError('pattern')" class="help-block">
                      Password must have a minimum of 8 characters<br>
                      and must include at least 1 number.
                    </div>
                    <input type="text" class="username" name="password" [(ngModel)]="resetModel.password" #password="ngModel" required [pattern]="passwordRegex" placeholder="Enter New Password" />
                  </div>
                  <div class="row">
                    <div class="col-xs-6 reset-link-button">
                      <button [disabled]="loading" class="btn btn-primary">Continue
                      </button>
                      <span *ngIf="loading" class="login-spinner spinner spinner-sm"></span>
                      <div *ngIf="loading">
                        <br>
                        <span>{{loginMessage}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-xs-6 reset-link-button">
                    <button type="button" [routerLink]="['/login']" class="btn btn-link">Return to log in</button>
                  </div>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showForm === 'recover'" class="main-container fl-login login-container">

  <div class="container container-table">
    <div class="login-table-middle">
      <div class="clr-col-md-8 clr-offset-md-2">
        <div class="card login-card">
          <div class="card-group">

            <div class="card-left py-3 hidden-md-down card-login card-left">
              <div class="card-block text-xs-center">
                <div class="logo-wrapper">
                  <img src="assets/img/logo_200px.png" class="logo-login" alt="Legend Analytics" />
                </div>
              </div>
            </div>
            <div class="card-right p-2">
              <div class="card-block">
                <h1>Legend Analytics</h1>
                <form *ngIf="showForm === 'recover'" class="login" name="recoverForm" (ngSubmit)="rf.form.valid && sendRecoveryLink()" #rf="ngForm" novalidate>
                  <div *ngIf="!hasReset">
                    <div class="resetExpired" *ngIf="tokenLinkInvalid">
                      The recovery link you selected has expired. Re-submit your email address to receive a new recovery link.
                    </div>
                    <p class="text-muted">We'll send a recovery link to:</p>
                    <div class="login-group" [ngClass]="{ 'has-error': rf.submitted && !username.valid }">
                      <div *ngIf="rf.submitted && username.hasError('required')" class="help-block">Email is
                        required
                      </div>
                      <div *ngIf="rf.submitted && username.hasError('pattern')" class="help-block">Email is
                        invalid
                      </div>
                      <input clrInput type="text" class="username" name="username" [(ngModel)]="recoverModel.username" #username="ngModel" required [pattern]="emailRegex" placeholder="Enter Email" />
                    </div>
                    <div class="clr-row">
                      <br />
                    </div>
                    <div class="clr-row">
                      <div class="clr-col-xs-6 recovery-link-button">
                        <button [disabled]="loading" class="btn btn-primary">Send recovery link
                        </button>
                        <span *ngIf="loading" class="login-spinner spinner spinner-sm"></span>
                        <div *ngIf="loading">
                          <br>
                          <span>{{loginMessage}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="hasReset">
                    <p class="text-muted">We sent a recovery link to your email address:</p>
                    <h3>{{recoverModel.username}}</h3>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-xs-6 recovery-link-button">
                      <button type="button" [routerLink]="['/login']" class="btn btn-link">Return to log in</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showForm === 'reset'" class="main-container fl-login login-container">
  <div class="container container-table">
    <div class="login-table-middle">
      <div class="clr-col-md-8 clr-offset-md-2">
        <div class="card login-card">
          <div class="card-group">

            <div class="card-left py-3 hidden-md-down card-login card-left">
              <div class="card-block text-xs-center">
                <div class="logo-wrapper">
                  <img src="assets/img/logo_200px.png" class="logo-login" alt="Legend Analytics" />
                </div>
              </div>
            </div>
            <div class="card-right p-2">

              <div class="card-block">
                <div class="card-text">
                  <form class="login" name="resetForm" (ngSubmit)="ref.form.valid && setPassword()" #ref="ngForm" novalidate>

                    <h1>Legend Analytics</h1>
                    <div>
                      <p class="text-muted">Choose a new password:</p>
                      <div class="login-group" [ngClass]="{ 'has-error': ref.submitted && !password.valid }">
                        <div *ngIf="ref.submitted && password.hasError('required')" class="help-block">Password is
                          required
                        </div>
                        <div *ngIf="ref.submitted && password.hasError('pattern')" class="help-block">
                          Password must have a minimum of 8 characters<br>
                          and must include at least 1 number.
                        </div>
                        <input clrInput type="password" class="username" name="password" [(ngModel)]="resetModel.password" #password="ngModel" required [pattern]="passwordRegex" placeholder="Enter New Password" />
                      </div>
                      <div class="row">
                        <br />
                      </div>
                      <div class="row">
                        <div class="col-xs-6 reset-link-button">
                          <button [disabled]="loading" class="btn btn-primary">Continue
                          </button>
                          <span *ngIf="loading" class="login-spinner spinner spinner-sm"></span>
                          <div *ngIf="loading">
                            <br>
                            <span>{{loginMessage}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-xs-6 reset-link-button">
                        <button type="button" [routerLink]="['/login']" class="btn btn-link">Return to log in</button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>



            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
